import React from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMediaQuery } from 'react-responsive';
import getLanguageId from '../../../src/utils/i18n/getLanguageId';

// Images and icons
import homeHeadBg from '../../../public/images/home/home_head.png';
import AcademyLogo from '../../../public/images/academy_logo.png';
import AcademyLogoMobile from '../../../public/images/home/logo_academy_mobile.svg';
import InfoIcon from '../../../public/icons/info_inverted.svg';

import styles from './Head.module.scss';

function Head({ config }) {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <section className={`${styles.head} flex a-stretch`}>
      <div className={styles.head_bg}>
        <Image src={homeHeadBg} layout="fill" objectFit="cover" alt="Tennis court" priority />
      </div>
      <div className={`${styles.head_content} flex-auto flex center d-col`}>
        {isMobile ? (
          <div className={styles.mobileLogo}>
            <AcademyLogoMobile className="m-b-20" />
          </div>
        ) : (
          <div className={`${styles.logo} m-b-30`}>
            <Image
              src={AcademyLogo}
              alt="Academy Logo"
            />
          </div>
        )}

        <h1
          className={`${styles.mainTitle} fs-34 fw-500 ${config?.headerShow ? styles.with_config_header : ''}`}
          dangerouslySetInnerHTML={{ __html: t('home.head.title') }}
        />

        {config?.headerShow
          && (config.headerTitles[getLanguageId(locale)]
          || config.headerSubtitles[getLanguageId(locale)]) ? (
            <div className={`${styles.config_header} flex a-center`}>
              <InfoIcon className={`${styles.icon} m-r-20`} />
              <div>
                <p className="fw-500 m-b-5">
                  {config.headerTitles[getLanguageId(locale)]}
                </p>
                <p className="fs-14">
                  {config.headerSubtitles[getLanguageId(locale)]}
                </p>
              </div>
            </div>
          ) : null}
      </div>
    </section>
  );
}

Head.propTypes = {
  config: PropTypes.shape({}),
};

Head.defaultProps = {
  config: null,
};

export default Head;
