import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useFormik } from 'formik';
import { addDays, isSameYear } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { omit } from 'lodash';
import MicroModal from 'micromodal';
import { useMediaQuery } from 'react-responsive';
import { useAppContext } from '../../../src/store/AppContext';
import modalConfig from '../../../src/utils/modalConfig';
import searchFormSchema from '../../../src/utils/validationSchema/searchForm';
import bookingStep from '../../../src/enum/bookingStep';
import useDataLayer from '../../../src/hooks/useDataLayer';
import useWeekId from '../../../src/hooks/useWeekId';
import useLocalizedDate from '../../../src/hooks/useLocalizedDate';
import cn from '../../../src/utils/cn';

// Components
import InputWithPanel from '../../molecules/InputWithPanel/InputWithPanel';
import Button from '../../atoms/Button/Button';
import Weeks from './Weeks/Weeks';
import Players from './Players/Players';
import Hosting from './Hosting/Hosting';

// Images
import HomePicto from '../../../public/icons/home.svg';
import CalendarPicto from '../../../public/icons/calendar.svg';
import PlayerPicto from '../../../public/icons/player.svg';

import styles from './SearchBar.module.scss';

function SearchBar({
  offers, weeks, packages,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const searchInputDatesRef = useRef();
  const searchInputPlayersRef = useRef();
  const searchInputHostingRef = useRef();

  const [{ players: storedPlayers }] = useAppContext();
  const { gtmEventSearchClick } = useDataLayer();
  const { getDateFromWeekId } = useWeekId();
  const { getDayMonthDate, getFullDate } = useLocalizedDate();

  let intersectionObserver;

  const [isSticky, setIsSticky] = useState(false);
  const [isMobileStickySearchVisible, setIsMobileStickySearchVisible] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFlexibleSearch, setIsFlexibleSearch] = useState(false);

  const handleSearch = (values) => {
    setSearchLoading(true);
    router.push({
      pathname: bookingStep.route.stage,
      query: {
        ...omit(values, 'players'),
        ...values.players,
      },
    });
  };
  const handleSubmit = (values) => {
    if (storedPlayers?.length) {
      // if user already has a cart in progress, ask before launching a new search
      MicroModal.show('modal-reset-search', {
        ...modalConfig,
        onClose: (modal) => {
          if (modal.dataset.reset) handleSearch(values);
        },
      });
    } else if (values) {
      handleSearch(values);
    }

    gtmEventSearchClick(values, isFlexibleSearch);
  };
  const formik = useFormik({
    initialValues: {
      weeks: [],
      weekend: false,
      players: {
        baby: 0,
        child: 0,
        adult: 0,
      },
      hosting: null,
    },
    onSubmit: (values) => handleSubmit(values),
    validationSchema: searchFormSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const datesWereChosen = (formikvalues) => formikvalues.values.weeks.length !== 0;
  const playerNumbersWereChosen = (formikvalues) => {
    const adults = formikvalues.values.players.adult;
    const children = formikvalues.values.players.child;
    const babies = formikvalues.values.players.baby;
    return adults !== 0 || children !== 0 || babies !== 0;
  };
  const handlePanelChange = () => {
    if (datesWereChosen(formik) && playerNumbersWereChosen(formik) && searchInputHostingRef.current) {
      searchInputHostingRef.current.openPanel();
    } else if (datesWereChosen(formik) && searchInputPlayersRef.current) {
      searchInputPlayersRef.current.openPanel();
    }
  };

  const handleSticky = (element) => {
    intersectionObserver = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      { threshold: [1] },
    );
    intersectionObserver.observe(element);
  };

  const handleScroll = () => {
    const hasScrollMoreThanEngine = window.scrollY > 600;
    const pageHeight = document.body.offsetHeight - window.innerHeight;
    const hasScrollToBottom = window.scrollY > (pageHeight - 300);
    setIsMobileStickySearchVisible(hasScrollMoreThanEngine && !hasScrollToBottom);
  };

  useEffect(() => {
    const stickySearchBar = document.querySelector('#stickySearchBar');
    const stickyTimeOut = setTimeout(() => { handleSticky(stickySearchBar); }, 350);
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(stickyTimeOut);
      if (intersectionObserver) intersectionObserver.unobserve(stickySearchBar);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getWeeksValue = () => {
    const selectedWeeks = formik.values.weeks;
    const isWeekendMode = formik.values.weekend;
    let value = [];

    if (selectedWeeks?.length) {
      value = selectedWeeks.map((weekId) => {
        const monday = getDateFromWeekId(weekId);
        const startDay = isWeekendMode ? addDays(monday, 5) : monday;
        const endDay = addDays(startDay, isWeekendMode ? 1 : 4);

        const start = isSameYear(startDay, endDay) ? getDayMonthDate(startDay, true) : getFullDate(startDay, true);
        const end = getFullDate(endDay, true);

        return `${start} - ${end}`;
      });
    }

    return value;
  };

  const getPlayersValue = () => {
    const playersList = [];
    const { players } = formik.values;

    Object.keys(players).forEach((player) => {
      if (players[player] > 0) {
        playersList.push(
          `${players[player]} ${players[player] > 1
            ? t(`search.players.${player}.multi`).toLowerCase()
            : t(`search.players.${player}`).toLowerCase()}`,
        );
      }
    });

    return playersList.length ? [playersList.join(', ')] : [];
  };

  const handleOpenSearchEngine = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    searchInputDatesRef?.current?.openPanel();
  };

  return (
    <>
      <section id="stickySearchBar" className={`${styles.bar_wrapper} ${isSticky ? styles.is_sticky : ''} flex center`}>
        <div className={styles.bar}>
          {!isTablet ? (
            <p className={`${styles.title} fs-20 fw-700`}>{t('bookCamp')}</p>
          ) : null}

          <div className={`${styles.sticky_max} flex center tablet-d-col`}>
            <div className={`${styles.r_sep} ${styles.input_container}`}>
              <InputWithPanel
                ref={searchInputDatesRef}
                label={t('search.input.dates')}
                picto={<CalendarPicto />}
                values={getWeeksValue()}
                withCloseBtn
                hasAutoScroll
                withCheckProgress
                isRequired
                withNextButton
                onClickFromParent={handlePanelChange}
              >
                <Weeks
                  offers={offers}
                  weeks={weeks}
                  packages={packages}
                  formik={formik}
                  setIsFlexibleSearch={setIsFlexibleSearch}
                />
              </InputWithPanel>
            </div>
            <div className={`${styles.r_sep} ${styles.input_container}`}>
              <InputWithPanel
                ref={searchInputPlayersRef}
                label={t('search.input.players')}
                picto={<PlayerPicto />}
                values={getPlayersValue()}
                withCloseBtn
                hasAutoScroll
                withCheckProgress
                isRequired
                withNextButton={datesWereChosen(formik)}
                onClickFromParent={handlePanelChange}
              >
                {datesWereChosen(formik) ? (
                  <Players formik={formik} />
                ) : <p>{t('search.hosting.chooseDates')}</p>}
              </InputWithPanel>
            </div>
            <div className={styles.input_container}>
              <InputWithPanel
                ref={searchInputHostingRef}
                label={t('search.input.hosting')}
                picto={<HomePicto />}
                align="right"
                values={
                  formik.values.hosting !== null
                    ? [formik.values.hosting ? t('search.hosting.inclued') : t('search.hosting.notInclued')]
                    : []
                }
                withCloseBtn
                hasAutoScroll
                withCheckProgress
                isRequired
              >
                {datesWereChosen(formik) && playerNumbersWereChosen(formik) ? (
                  <Hosting formik={formik} />
                ) : <p>{t('search.hosting.choosePlayers')}</p>}
              </InputWithPanel>
            </div>

            <div className={`${styles.submit} m-r-20 m-l-40`}>
              <Button
                text="uppercase"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid || searchLoading}
                isLoading={searchLoading}
              >
                {t('search.submit')}
              </Button>
            </div>
          </div>
        </div>
      </section>

      <div
        className={cn([
          styles.sticky_mobile_search,
          isMobileStickySearchVisible && isTablet ? styles.show : '',
        ])}
      >
        <Button
          full
          onClick={handleOpenSearchEngine}
          text="uppercase"
        >
          {t('bookCamp')}
        </Button>
      </div>
    </>
  );
}

SearchBar.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  weeks: PropTypes.shape({}).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SearchBar;
